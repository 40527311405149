export const Wedding = [
    {
         "id":1,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651753600/Wedding%20Photography/TPL_8761ed_otszpq.jpg",
    },
     {
          "id":2,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651753548/Wedding%20Photography/DSC_3716_dgys9a.jpg",
    },
     {
          "id":3,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651753597/Wedding%20Photography/TPL_6336ed_flf3hn.jpg",
    },
     {
          "id":4,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651753587/Wedding%20Photography/TPL_6329edT_ketabl.jpg",
    },
     {
          "id":5,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651753583/Wedding%20Photography/DSC_4248_wzkhgt.jpg",
    },
     {
          "id":6,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651753585/Wedding%20Photography/TPL_7641_djtyak.jpg",
    },
    {
         "id":7,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651753578/Wedding%20Photography/DSC_3647_fzkbjw.jpg",
    },
     {
          "id":8,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651753572/Wedding%20Photography/DSC_4243ed_fonn0a.jpg",
    },
     {
          "id":9,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651753555/Wedding%20Photography/DSC_3736_whpomw.jpg",
    }]