export const CorporateHeadshots = [
    {
         "id":1,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651755577/Corporate%20headshot/DSC_3680ed_iftobd.jpg",
    },
     {
          "id":2,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651755543/Corporate%20headshot/KHP02122ed_jvjokl.jpg",
    },
     {
          "id":3,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651755589/Corporate%20headshot/DSC_3541ed_erarcq.jpg",
    },
     {
          "id":4,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651755618/Corporate%20headshot/KHP02114ed_czyidh.jpg",
    },
     {
          "id":5,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651755630/Corporate%20headshot/KHP02108ed_wzc19q.jpg",
    },
     {
          "id":6,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651755637/Corporate%20headshot/KHP02117ed_crdwdq.jpg",
    },
    {
         "id":7,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651755681/Corporate%20headshot/DSC_0760ed_molzja.jpg",
    },
     {
          "id":8,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651755654/Corporate%20headshot/DSC_2317ed_qs59bx.jpg",
    },
     {
          "id":9,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651755664/Corporate%20headshot/DSC_3547ed_fvlf3s.jpg",
    },
     {
          "id":10,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651755610/Corporate%20headshot/DSC_0706ed_ejoxad.jpg",
    }
    ,
     {
          "id":11,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651755540/Corporate%20headshot/DSC_2313ed_lz6szt.jpg",
    },
     {
          "id":12,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651755633/Corporate%20headshot/DSC_2272ed_jtjex1.jpg",
    }
    ]