import React from 'react'
import Nav from './Nav'

export default function Connect() {
    return (
        <div>
        <Nav>
        About Derick Otormax

        </Nav>
            
        </div>
    )
}
