import React from 'react'
import './nav.css'
import {FaTwitter} from 'react-icons/fa'
import {FaInstagram} from 'react-icons/fa'
import {FaFacebook} from 'react-icons/fa'

export default function Footer() {
    return (
        <div className="footer">
        <div className="info">
             &copy; COPYRIGHT| DEMAX MEDIA PRODUCTION | DERICK OTORMAX {new Date().getFullYear()}
             <hr width="1" size="500"/>
        </div>
        <div className="logo">
            <FaTwitter />&nbsp;
            <FaInstagram />&nbsp;
            <FaFacebook />
        </div>           
        </div>
    )
}
