import React from 'react'
import Nav from './Nav'
import "./connect.css"

export default function Connect() {
    return (
        <div>
        <Nav>
        <div className="connect">
       <div className="info">
            ABOUT DEMAX MEDIA PRODUCTION
        <hr></hr>
        <div className="cardy">
      <div className="cardy_image"><img src="https://res.cloudinary.com/demax-production/image/upload/v1654868910/My%20photo/image00006_kyynk8.jpg" alt="me" />
       </div>
       </div>        
       <p>
            
         We are a content creation, branding and production company that helps businesses, companies, NGO's and institutions to connect with their customers through visual digital content creation.
We specialize in corporate event coverage, corporate headshot, documentaries, wedding photography, real estate photography and live streaming services.
        <br/>
        <hr/>
        you can contact me on;<br/>

        Phone Number: 0741711413<br/>
        Email: demaxproductions@gmail.com


       </p>

       
       </div>
     
        </div>      
        
        
        </Nav>            
        </div>
    )
}
