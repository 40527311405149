import React from 'react'
import Masonry from 'react-masonry-css'
import {BabyBump} from '../../Images/BabyBump'
import Nav from '../Nav'
import '../overview.css'

export default function BabyBumps() {
    const breakpointColumnsObj = {
        default: 3,
        1100: 3,
        700: 2,
        500: 1
        };

    let items = BabyBump.map(function(item) {
  return <div>
  <img key={item.id} src={item.url} alt="img" />

  </div>
      
  
});

    return (
        <div>
        <Nav>
        <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
            >
            {items}
            </Masonry>
            </Nav>
            
        </div>
    )
}
