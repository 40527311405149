export const ArcImages = [
    {
         "id":1,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651756029/Architecture%20photography/Enkang%20Apartments/IMG_5079_us0hrg.jpg",
    },
     {
          "id":2,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651756031/Architecture%20photography/Enkang%20Apartments/IMG_5066_n2drqz.jpg",
    },
     {
          "id":3,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651756029/Architecture%20photography/Enkang%20Apartments/IMG_5071_acu8gi.jpg",
    },
     {
          "id":4,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651756018/Architecture%20photography/Enkang%20Apartments/IMG_5073_g8auix.jpg",
    },
     {
          "id":5,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651756013/Architecture%20photography/Enkang%20Apartments/IMG_5067_a4my3n.jpg",
    },
     {
          "id":6,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651756012/Architecture%20photography/Enkang%20Apartments/IMG_5077_iutuor.jpg",
    },
    {
         "id":7,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651756001/Architecture%20photography/Enkang%20Apartments/IMG_5074_eskt3l.jpg",
    },
     {
          "id":8,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651755998/Architecture%20photography/Enkang%20Apartments/IMG_5068_wy9izf.jpg",
    },
     {
          "id":9,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651757562/Architecture%20photography/Urban%20Oasis%20/KHP01577ed_ojn046.jpg",
    },
     {
          "id":10,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651757558/Architecture%20photography/Urban%20Oasis%20/KHP01542_zuhzod.jpg",
    },
    {
         "id":11,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651757551/Architecture%20photography/Urban%20Oasis%20/KHP01594_dnx9dn.jpg",
    },
     {
          "id":12,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651757545/Architecture%20photography/Urban%20Oasis%20/KHP01436ed_essmfe.jpg",
    },
     {
          "id":13,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651757527/Architecture%20photography/Urban%20Oasis%20/KHP01453ed_sttrdt.jpg",
    },
     {
          "id":14,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651757520/Architecture%20photography/Urban%20Oasis%20/KHP01643_seuqry.jpg",
    },
    {
         "id":15,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651757518/Architecture%20photography/Urban%20Oasis%20/KHP01484_yptv8j.jpg",
    },
    {
          "id":16,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651757514/Architecture%20photography/Urban%20Oasis%20/KHP01503_yv97zz.jpg",
    },
    {
         "id":17,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651757501/Architecture%20photography/Urban%20Oasis%20/KHP01501_xexpre.jpg",
    }

]