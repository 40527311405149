export const BabyBump = [
    {
         "id":1,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651755786/Baby%20bump/_88A2745ed_xrnwqa.jpg",
    },
     {
          "id":2,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651755784/Baby%20bump/_88A2804ed_m5rwpx.jpg",
    },
     {
          "id":3,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651755725/Baby%20bump/DSC_6851ed1_dkzu61.jpg",
    },
     {
          "id":4,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651755775/Baby%20bump/_88A2756ed_ntvn63.jpg",
    },
     {
          "id":5,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651755770/Baby%20bump/DSC_6909ed_rfrz5x.jpg",
    },
     {
          "id":6,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651755748/Baby%20bump/_MGL6338ed_uaciuw.jpg",
    },
    {
         "id":7,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651755746/Baby%20bump/_MGL2265ed_eqijmr.jpg",
    },
     {
          "id":8,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651755741/Baby%20bump/_MGL2261ed_xaicyz.jpg",
    },
     {
          "id":9,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651755732/Baby%20bump/_MGL2278ed_ridbml.jpg",
    },
     {
          "id":10,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651755737/Baby%20bump/_MGL6341ed_nimkxi.jpg",
    }
    ]