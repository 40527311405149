import React from 'react'
import { BrowserRouter,Routes, Route, Navigate} from "react-router-dom";
import Overview from './Components/Overview'
import Me from './Components/Me'
import Connect from './Components/Connect'
import Architecture from './Components/Portfolio/Architecture'
import BabyBumps from './Components/Portfolio/BabyBumps'
import BeautyFashion from './Components/Portfolio/BeautyFashion'
import Composite from './Components/Portfolio/Composite'
import CorporateEvent from './Components/Portfolio/CorporateEvent'
import CorporateHeadshot from './Components/Portfolio/CorporateHeadshot'
import Documentary from './Components/Portfolio/Documentary'
import ProductShoot from './Components/Portfolio/ProductShoot'
import Videos from './Components/Portfolio/Videos'
import Weddings from './Components/Portfolio/Weddings'

function App() {

  return (
    <div className="App">
    
    <BrowserRouter>
    <Routes> 
    <Route exact path="/" element={<Overview/>}  />
      
    <Route exact path="/me" element={<Me/>} />
    <Route exact path="/connect" element={<Connect/>} />
    <Route exact path="/architecture" element={<Architecture />} />
    <Route exact path="/babybumps" element={<BabyBumps />} />
    <Route exact path="/beautyfashion" element={<BeautyFashion />} />
    <Route exact path="/composite" element={<Composite />} />
    <Route exact path="/corporateheadshot" element={<CorporateHeadshot />} />
    <Route exact path="/corporateevent" element={<CorporateEvent />} />
    <Route exact path="/documentary" element={<Documentary />} />
    <Route exact path="/productshoot" element={<ProductShoot />} />
    <Route exact path="/videos" element={<Videos />} />
     <Route exact path="/weddings" element={<Weddings />} />

    <Route
      path="*"
      element={<Navigate to="/" />}
    /> 
    

    </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
