export const Products = [
    {
         "id":1,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651758626/Product%20Photography/9_rijgre.jpg",
    },
     {
          "id":2,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651758651/Product%20Photography/8_yxhsto.jpg",
    },
     {
          "id":3,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651758626/Product%20Photography/9_rijgre.jpg",
    },
     {
          "id":4,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651758649/Product%20Photography/6_jnfez0.jpg",
    },
     {
          "id":5,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651758649/Product%20Photography/5_vtb4n9.jpg",
    },
     {
          "id":6,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651758643/Product%20Photography/10_uhd5gm.jpg",
    },
    {
         "id":7,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651758637/Product%20Photography/1_qv9ip8.jpg",
    },
     {
          "id":8,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651758634/Product%20Photography/2_gaz0ey.jpg",
    },
     {
          "id":9,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651758641/Product%20Photography/3_dj4sb6.jpg",
    },
     {
          "id":10,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651758636/Product%20Photography/4_qcce7t.jpg",
    }
    ]