import React from 'react';
import './nav.css'
import { NavLink } from "react-router-dom";
import Footer from './Footer'

const Nav = ({children}) => {
    const [isActive, setisActive] = React.useState(false);

    return (
    <div>
    <nav className="navbar" role="navigation" aria-label="main navigation">
    <div className="navbar-brand">
    <a className="navbar-item" href="/">
     <h1>DEMAX MEDIA PRODUCTIONS</h1>
    </a>
      <h5
      className="hamburger"
       
        onClick={() => {
          setisActive(!isActive);
        }}
        
        role="button"
        className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarBasicExample"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </h5>
    </div>
    <div
      id="navbarBasicExample"
      className={`navbar-menu ${isActive ? "is-active" : ""}`}
    >
      <div className="navbar">
     
      <nav className="navbar-item">
       <NavLink to={"/connect"}>
          Connect

          </NavLink> 
      </nav>
      </div>
      <div className="navbar-item has-dropdown is-hoverable">
        <nav  className="navbar-link">
          PORTFOLIO
        </nav>

        <div className="navbar-dropdown">
         <nav className="navbar-item">
       <NavLink to={"/architecture"}>
        Architecture
          </NavLink> 
      </nav>

           <nav className="navbar-item">
       <NavLink to={"/babybumps"}>
          Baby Bumps

          </NavLink> 
      </nav>
         
           <nav className="navbar-item">
       <NavLink to={"/beautyfashion"}>
          Beauty and Fashion

          </NavLink> 
      </nav>
         
           <nav className="navbar-item">
       <NavLink to={"/composite"}>
          Composite

          </NavLink> 
      </nav>
          
           <nav className="navbar-item">
       <NavLink to={"/corporateevent"}>
          Corporate Event Coverage

          </NavLink> 
      </nav>
           <nav className="navbar-item">
       <NavLink to={"/corporateheadshot"}>
          Corporate Headshot

          </NavLink> 
      </nav>
           <nav className="navbar-item">
       <NavLink to={"/documentary"}>
          Documentary

          </NavLink> 
      </nav>
           <nav className="navbar-item">
       <NavLink to={"/productshoot"}>
           Product Shoot

          </NavLink> 
      </nav>
           <nav className="navbar-item">
       <NavLink to={"/videos"}>
          Videos

          </NavLink> 
      </nav>
           <nav className="navbar-item">
       <NavLink to={"/weddings"}>
          Wedding Photography

          </NavLink> 
      </nav>
          
        </div>
     
    </div>
    
    </div>
   
</nav>
{children}
<Footer />
        </div>
    );
}

export default Nav;
