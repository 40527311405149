export const HomeImages = [
     {
          "id":1,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651758134/Composite/image00001_mionsk.jpg",
    },   
      {
         "id":2,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651755577/Corporate%20headshot/DSC_3680ed_iftobd.jpg",
    },
     {
          "id":3,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651754963/Corporate%20event%20coverage/DSC_1814ed_mzqlkz.jpg",
    },
     {
          "id":4,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651753600/Wedding%20Photography/TPL_8761ed_otszpq.jpg",
    },
     {
          "id":5,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651754959/Corporate%20event%20coverage/DSC_5873ed_tpf67v.jpg",
    },
     {
         "id":6,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651757947/Beauty%20and%20Fashion/DSC_6993ed_hdj13g.jpg",
    },
    {
         "id":7,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651753583/Wedding%20Photography/DSC_4248_wzkhgt.jpg",
    },
    {
          "id":8,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651755725/Baby%20bump/DSC_6851ed1_dkzu61.jpg",
    },
    {
         "id":9,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651755681/Corporate%20headshot/DSC_0760ed_molzja.jpg",
    },

//     
]