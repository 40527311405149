export const Documentaryy = [
    {
         "id":1,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651758566/Documentary/LAW_5905_m5wzcr.jpg",
    },
     {
          "id":2,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651758379/Documentary/LAW_6307_eugzjc.jpg",
    },
     {
          "id":3,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651758567/Documentary/LAW_5898_gabib9.jpg",
    },
     {
          "id":4,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651758383/Documentary/LAW_5872_emkgll.jpg",
    },
     {
          "id":5,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651758379/Documentary/LAW_5860_ztra0w.jpg",
    },
     {
          "id":6,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651758379/Documentary/LAW_5912_n1gh9b.jpg",
    },
    {
         "id":7,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651758360/Documentary/DSC_6044_sntaoo.jpg",
    },
     {
          "id":8,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651758356/Documentary/Dsc_0001_mjuo6v.jpg",
    },
     {
          "id":9,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651758327/Documentary/DSC_9074_w0zruc.jpg",
    },
     {
          "id":10,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651758342/Documentary/Dsc_0002_zlme4c.jpg",
    },
    {
          "id":11,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651758339/Documentary/DSC_8990_zimcxc.jpg",
    }
    ]