export const BeautyFashions = [
    {
         "id":1,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651757947/Beauty%20and%20Fashion/DSC_6993ed_hdj13g.jpg",
    },
     {
          "id":2,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651757946/Beauty%20and%20Fashion/DSC_7016ed_oaxrw1.jpg",
    },
     {
          "id":3,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651757793/Beauty%20and%20Fashion/DSC_6923evanedJ_b2zj10.jpg",
    },
     {
          "id":4,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651757939/Beauty%20and%20Fashion/DSC_1039edinsta_zfwj3c.jpg",
    },
     {
          "id":5,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651757812/Beauty%20and%20Fashion/DSC_6533ed_umlf8d.jpg",
    },
     {
          "id":6,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651757769/Beauty%20and%20Fashion/DSC_6925bev_zcvlub.jpg",
    },
    {
         "id":7,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651757807/Beauty%20and%20Fashion/DSC_5899edb_ebysyz.jpg",
    },
     {
          "id":8,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651757810/Beauty%20and%20Fashion/DSC_5808ed_lkew2s.jpg",
    },
     {
          "id":9,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651757759/Beauty%20and%20Fashion/DSC_5901ed_byidvf.jpg",
    },
     {
          "id":10,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651757769/Beauty%20and%20Fashion/DSC_5044ed_corhas.jpg",
    },
     {
         "id":11,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651757775/Beauty%20and%20Fashion/DSC_0122ed_ypbqwp.jpg",
    },
     {
          "id":12,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651757742/Beauty%20and%20Fashion/DSC_5041ed_piostv.jpg",
    },
     {
          "id":13,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651757723/Beauty%20and%20Fashion/DSC_6514ed_f52fwx.jpg",
    },
     {
          "id":14,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651757717/Beauty%20and%20Fashion/DSC_5036ed-2_vaqhxf.jpg",
    },
     {
          "id":15,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651757723/Beauty%20and%20Fashion/DSC_0117ed_r66pzx.jpg",
    },
     {
          "id":16,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651757679/Beauty%20and%20Fashion/DCS-005_vef7vk.jpg",
    },
    {
         "id":17,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651757679/Beauty%20and%20Fashion/DCS-005_vef7vk.jpg",
    },
     {
          "id":18,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651757675/Beauty%20and%20Fashion/DCS-002_sh5qyd.jpg",
    },
     {
          "id":19,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651757677/Beauty%20and%20Fashion/DCS-004_bgv3cf.jpg",
    },
     {
          "id":20,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651757674/Beauty%20and%20Fashion/DCS-001_eq0vmk.jpg",
    },
    {
          "id":21,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651757680/Beauty%20and%20Fashion/DCS-009_awdq7r.jpg",
    },
     {
          "id":22,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651757674/Beauty%20and%20Fashion/DCS-0011_r16x0v.jpg",
    }
    ]