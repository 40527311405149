export const CorporateEvents = [
    {
         "id":1,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651754963/Corporate%20event%20coverage/DSC_1814ed_mzqlkz.jpg",
    },
     {
          "id":2,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651754958/Corporate%20event%20coverage/DSC_5738ed_qm1ei3.jpg",
    },
     {
          "id":3,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651754959/Corporate%20event%20coverage/DSC_5873ed_tpf67v.jpg",
    },
     {
          "id":4,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651754929/Corporate%20event%20coverage/DSC_5698ed_ycvnvv.jpg",
    },
     {
          "id":5,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651754952/Corporate%20event%20coverage/DSC_5856ed_wqbmzb.jpg",
    },
     {
          "id":6,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651754943/Corporate%20event%20coverage/DSC_5723ed_n08hgl.jpg",
    },
    {
         "id":7,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651754871/Corporate%20event%20coverage/DSC_3128_nir7oj.jpg",
    },
     {
          "id":8,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651754865/Corporate%20event%20coverage/KHP02087_xhlqpd.jpg",
    },
     {
          "id":9,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651754865/Corporate%20event%20coverage/DSC_3135_fl6i8a.jpg",
    },
     {
          "id":10,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651754839/Corporate%20event%20coverage/DSC_1017_civyoe.jpg",
    },
     {
         "id":11,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651754842/Corporate%20event%20coverage/DSC_1021_jnnnky.jpg",
    },
     {
          "id":12,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651754838/Corporate%20event%20coverage/DSC_1272_qsohtz.jpg",
    },
     {
          "id":13,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651754860/Corporate%20event%20coverage/DSC_1172-3_nksalk.jpg",
    },
     {
          "id":14,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651754844/Corporate%20event%20coverage/DSC_4052_myxcro.jpg",
    },
     {
          "id":15,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651754918/Corporate%20event%20coverage/KHP02086_iwbwxi.jpg",
    },
     {
          "id":16,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651754895/Corporate%20event%20coverage/DSC_1210_fec2lj.jpg",
    },
    {
         "id":17,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651754843/Corporate%20event%20coverage/DSC_3119_osxwql.jpg",
    },
     {
          "id":18,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651754823/Corporate%20event%20coverage/DSC_1277-2_hnei0j.jpg",
    },
     {
          "id":19,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651754890/Corporate%20event%20coverage/DSC_7387ed_om6awy.jpg",
    }]