export const Compositee = [
    {
         "id":1,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651758134/Composite/image00001_mionsk.jpg",
    },
     {
          "id":2,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651758130/Composite/image00002_se314j.jpg",
    },
     {
          "id":3,
         "url":"https://res.cloudinary.com/demax-production/image/upload/v1651758049/Composite/blue4ed_brqmgk.jpg",
    }]